
export default {
    name: "FullscreenMedia",
    components: {
        MediaModal: () => import("./MediaModal"),
    },
    data: () => ({
        media: null,
        attach: false,
        isMounted: false,
        isInit: false,
        snackbar: false,
    }),
    methods: {
        open(media, options) {
            this.isInit = true
            const { attach, initialIndex, initialId } = options || {}
            if (!Array.isArray(media)) media = [media]
            const mediaId = initialId || media[initialIndex || 0]?.id
            this.$router.push({
                query: {
                    ...this.$route.query,
                    media: mediaId,
                },
            })
            this.media = media
            this.attach = attach
            setTimeout(() => {
                if (!this.isMounted) {
                    this.isInit = false
                    this.snackbar = true
                }
            }, 5000)
        },
        close() {
            this.media = null
            this.attach = false
            if (this.$route.query.media) this.$router.back()
        },
    },
}
