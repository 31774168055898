import Vue from "vue"
const NON_DATA_AXIOS_CALL = ["get", "delete", "head", "options"]

let instance = null

export default ({ app }, inject) => {
    function generate(type, url, data = null, providedOptions = {}) {
        const cancelSource = app.$axios.CancelToken.source()
        const obj = {}
        let axiosRequest = app.$axios[type]
        Vue.set(obj, "state", "pending")
        Vue.set(obj, "error", null)
        Vue.set(obj, "progress", 0)
        const defaultOptions = {
            onUploadProgress: (progressEvent) => {
                const value = (100 * progressEvent.loaded) / progressEvent.total
                obj.progress = Number(value.toFixed(0))
            },
            cancelToken: cancelSource.token,
        }
        let options = { ...defaultOptions, ...providedOptions }

        if (NON_DATA_AXIOS_CALL.includes(type)) {
            /** Get can provide params via options */
            if (data) {
                options = { ...options, params: data }
            }
            axiosRequest = axiosRequest(url, options)
        } else axiosRequest = axiosRequest(url, data, options)
        axiosRequest = axiosRequest.then((response) => {
            if (response.status === 201 || response.status === 200) {
                obj.state = "success"
                obj.progress = 100
            } else {
                obj.state = "warning"
            }
            if (providedOptions?.rawResponse) return response.data
            return response.data.data ?? response.data
        })
        axiosRequest = axiosRequest.catch((error) => {
            obj.state = "error"
            obj.error = error
            throw new Error(error)
        })

        obj.then = (func) => axiosRequest.then(func)
        obj.catch = (func) => axiosRequest.catch(func)
        obj.finally = (func) => axiosRequest.finally(func)
        obj.cancel = () => {
            obj.state = "cancel"
            cancelSource.cancel()
        }
        obj.request = axiosRequest
        return obj
    }

    function get(url, data = null, providedOptions = {}) {
        return generate("get", url, data, providedOptions)
    }

    function post(url, data = null, providedOptions = {}) {
        return generate("post", url, data, providedOptions)
    }

    function put(url, data = null, providedOptions = {}) {
        return generate("put", url, data, providedOptions)
    }

    function del(url, providedOptions = {}) {
        return generate("delete", url, providedOptions)
    }

    const api = {
        get,
        post,
        put,
        del,
        generate,
    }

    function run(serviceMethod, ...params) {
        return serviceMethod.call(api, ...params)
    }
    instance = {
        ...api,
        run,
    }
    inject("api", instance)
}

export function getApi() {
    return instance
}
