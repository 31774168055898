import Vue from "vue"
import ChatFormHandlerClass from "@/components/global/ChatFormHandler"

export default async function ({ app }, inject) {
    app.mixins = app.mixins || []
    let HandlerComponent
    app.mixins.push({
        async mounted() {

            const ChatFormHandler = Vue.component(
                "ChatFormHandler",
                ChatFormHandlerClass
            )
            HandlerComponent = new ChatFormHandler({ parent: this})
            let ele = document.getElementById("__nuxt")
            var node = document.createElement("div")
            node.setAttribute("id", "additionals")
            ele.appendChild(node)
            HandlerComponent.$mount("#additionals")
        },
    })
    function open(comp, props = {}) {
        HandlerComponent.open(comp, props)
    }

    inject("chatForm", { open })
}
