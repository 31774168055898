import Vue from "vue"
import { computed } from "vue"

import CookieBannerClass from "@/components/global/CookieBanner"

const COOKIE_NAME = "consent"
const COOKIE_MAX_AGE = 60 * 60 * 24 * 365 // 1 Year
const COOKIE_PATH = "/"

export default async function ({app, nuxtState}, inject) {
    const cookies = Vue.observable({
        marketing: false,
        performance: false,
    })
    const cookieData = app.$cookies.get(COOKIE_NAME)
    if (cookieData) {
        cookies.marketing = cookieData.marketing
        cookies.performance = cookieData.performance

    } else {
        if (nuxtState?.layout !== "embed"){
            app.mixins = app.mixins || []
            app.mixins.push({
                async mounted() {
                    const CookieBanner = Vue.component(
                        "CookieBanner",
                        CookieBannerClass
                    )
                    const CookieBannerComponent = new CookieBanner({ parent: this })
                    let ele = document.getElementById("main")
                    var node = document.createElement("div")
                    node.setAttribute("id", "cookieBanner")
                    ele.appendChild(node)
                    CookieBannerComponent.$mount("#cookieBanner")
                },
            })
        }
    }

    const set = ({ marketing, performance }) => {
        cookies.marketing = marketing
        cookies.performance = performance
        app.$cookies.set(COOKIE_NAME, { marketing, performance }, {
            path: COOKIE_PATH,
            maxAge: COOKIE_MAX_AGE,
        })
    }

    inject("consent", Vue.observable({
        acceptAll() {
            set({ marketing: true, performance: true })
        },
        set,
        marketing: computed(() => cookies.marketing),
        performance: computed(() => cookies.performance)
    }))
}
