
import { mdiShareVariant, mdiClose } from "@mdi/js"
import { getFullBaseURL } from "@/helpers"
import Campaign from "@/helpers/campaign"

export default {
    name: "ShareSpeedModal",
    components: {
        CopyToClipboardText: () =>
            import("@/components/base/CopyToClipboardText"),
        Modal: () => import("@/components/base/Modal"),
    },
    props: {
        facebook: {
            default: true,
            type: Boolean,
        },
        twitter: {
            default: true,
            type: Boolean,
        },
        email: {
            default: true,
            type: Boolean,
        },
        pinterest: {
            default: true,
            type: Boolean,
        },
        xing: {
            default: true,
            type: Boolean,
        },
        linkedin: {
            default: true,
            type: Boolean,
        },
        whatsapp: {
            default: true,
            type: Boolean,
        },
    },
    icons: {
        mdiShareVariant,
        mdiClose,
    },
    data() {
        return {
            isModalOpen: false,
            campaign: new Campaign(null, "share", "share"),
            socialNetworks: [
                {
                    name: "Facebook",
                    show: this.facebook,
                    link:
                        "https://www.facebook.com/sharer/sharer.php?u=" +
                        encodeURIComponent(getFullBaseURL() + this.$route.path),
                    img: require("assets/icons/social/facebook.png"),
                },
                {
                    name: "Twitter",
                    show: this.twitter,
                    link:
                        "http://twitter.com/share?text=Schau was ich gefunden habe&url=" +
                        encodeURIComponent(
                            getFullBaseURL() + this.$route.path
                        ) +
                        "&hashtags=EinBlick,Ausbildung,Praktika",
                    img: require("assets/icons/social/twitter.png"),
                },
                {
                    name: "Email",
                    show: this.email,
                    link:
                        "mailto:?subject=Das könnte dich interessieren&body=" +
                        getFullBaseURL() +
                        this.$route.path,
                    img: require("assets/icons/social/email.png"),
                },
                {
                    name: "Pinterest",
                    show: this.pinterest,
                    link:
                        "http://pinterest.com/pin/create/button/?url=" +
                        getFullBaseURL() +
                        this.$route.path,
                    img: require("assets/icons/social/pinterest.png"),
                },
                {
                    name: "Xing",
                    show: this.xing,
                    link:
                        "https://www.xing.com/spi/shares/new?url=" +
                        getFullBaseURL() +
                        this.$route.path,
                    img: require("assets/icons/social/xing.png"),
                },
                {
                    name: "LinkedIn",
                    show: this.linkedin,
                    link:
                        "https://www.linkedin.com/shareArticle?mini=true&url=" +
                        getFullBaseURL() +
                        this.$route.path +
                        "&title=Schau was ich gefunden habe",
                    img: require("assets/icons/social/linkedin.png"),
                },
                {
                    name: "WhatsApp",
                    show: this.whatsapp,
                    link:
                        "whatsapp://send?text=Der EinBlick könnte dich interessieren \n\n" +
                        getFullBaseURL() +
                        this.$route.path,
                    attrs: { dataAction: "share/whatsapp/share" },
                    img: require("assets/icons/social/whatsapp.png"),
                },
            ],
        }
    },
    computed: {
        link() {
            return (
                getFullBaseURL() +
                this.$route.path +
                "?" +
                this.campaign.toQueryString()
            )
        },
        round() {
            return !this.$slots.default
        },
    },
    methods: {
        openShareDialog() {
            if (navigator.share) {
                try {
                    navigator.share({
                        title: this.title,
                        url: this.link,
                    })
                } catch (e) {
                    console.info("Share: Share cancelled by user: ", e)
                }
            } else {
                this.isModalOpen = !this.isModalOpen
            }
        },
    },
}
