import {
    ref,
    useRoute,
    provide,
} from "@nuxtjs/composition-api"

export default class Campaign {
    constructor(id, source, medium) {
        this.id = id
        this.source = source
        this.medium = medium
    }
    sendEvent() {
        // TODO: implement
        console.log('Sending event', this)
    }
    toQuery() {
        const query = {}
        if (this.id) query['utm_id'] = this.id
        if (this.source) query['utm_source'] = this.source
        if (this.medium) query['utm_medium'] = this.medium
        return query
    }
    toQueryString() {
        const query = this.toQuery()
        return Object.keys(query)
            .map(key => `${key}=${query[key]}`)
            .join('&')
    }

    /** returns null if any param is missing */
    static fromQuery(query) {
        if (!query) return null
        if (!query['utm_source'] || !query['utm_medium']) return null
        return new Campaign(
            query['utm_id'],
            query['utm_source'],
            query['utm_medium']
        )
    }
}


export function setupCampaign() {
    const route = useRoute()
    const campaign = ref(Campaign.fromQuery(route.value.query))
    provide("campaign", campaign)
    return campaign
}