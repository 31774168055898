import Router from 'vue-router'

const BUSINESS_FOLDER = 'business'
const ROOT_FOLDER = 'root'
const HOSTNAME_REGEX = /([a-z-]+)\.(einblick|localhost)/i

export function createRouter(ssrContext, createDefaultRouter, routerOptions) {
  const options = routerOptions || createDefaultRouter(ssrContext).options
  let businessSlug = null

  if (process.server && ssrContext && ssrContext.nuxt && ssrContext.req) {
    const hostname = ssrContext.req.headers.host.replace('www.', '')
    const match = hostname.match(HOSTNAME_REGEX)
    const subdomain = match ? match[1] : null

    /* Save to the object that will be sent to the client as inline-script */
    ssrContext.nuxt.businessSlug = subdomain
    businessSlug = subdomain
  }

  if (process.client) {
    const nuxt = window.__NUXT__
    /* Get what we saved on SSR */
    if (nuxt?.businessSlug) {
      businessSlug = nuxt.businessSlug
    }
  }

  const usedDir = businessSlug ? BUSINESS_FOLDER : ROOT_FOLDER
  const disabledDir = businessSlug ? ROOT_FOLDER : BUSINESS_FOLDER

  const mappedRoutes = mapRoutes(sortRoutes(options.routes), usedDir)
  const routes = mappedRoutes.filter((route) => !route.path.includes(disabledDir))
  return new Router({ ...options, routes })
}

/**
 * Sorting: Global first, then matching routes with less params first, index page last
 */
function sortRoutes(routes) {
  return routes.sort((a, b) => {
    let score = 0
    score += (a.path.match(/:/g) || []).length
    score -= (b.path.match(/:/g) || []).length
    if (isGlobalDir(b)) score -= 10
    if (isGlobalDir(a)) score += 10
    if (a.path === '/business') score += 100
    if (b.path === '/business') score -= 100
    return score
  })
}

function mapRoutes(routes, rootDir) {
  return routes.map((route) => {
    if (!route.path.includes(rootDir)) return route
    return {
      ...route,
      path: route.path.replace(new RegExp(`${rootDir}\\/?`), ''),
    }
  })
}


function isGlobalDir(route) {
  return route.path.includes(ROOT_FOLDER) || route.path.includes(BUSINESS_FOLDER)
}