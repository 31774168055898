import { render, staticRenderFns } from "./error.vue?vue&type=template&id=415afe76&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=415afe76&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "415afe76",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Divider: require('/builds/oabat/einblick/web/src/components/oabatify/Divider.vue').default,Button: require('/builds/oabat/einblick/web/src/components/oabatify/Button.vue').default})
