import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _12614761 = () => interopDefault(import('../pages/business/index.vue' /* webpackChunkName: "pages/business/index" */))
const _01cdfdb8 = () => interopDefault(import('../pages/business/index/index.vue' /* webpackChunkName: "pages/business/index/index" */))
const _46784670 = () => interopDefault(import('../pages/business/index/_businessSiteCombinedSlug.vue' /* webpackChunkName: "pages/business/index/_businessSiteCombinedSlug" */))
const _82302116 = () => interopDefault(import('../pages/datenschutz.vue' /* webpackChunkName: "pages/datenschutz" */))
const _2369e349 = () => interopDefault(import('../pages/impressum.vue' /* webpackChunkName: "pages/impressum" */))
const _22efdc30 = () => interopDefault(import('../pages/business/embed.vue' /* webpackChunkName: "pages/business/embed" */))
const _4c3a463b = () => interopDefault(import('../pages/root/_storyblok.vue' /* webpackChunkName: "pages/root/_storyblok" */))
const _1f5e74f4 = () => interopDefault(import('../pages/business/_businessSiteCombinedSlug/_jobSlug/index.vue' /* webpackChunkName: "pages/business/_businessSiteCombinedSlug/_jobSlug/index" */))
const _6710dfe6 = () => interopDefault(import('../pages/business/_businessSiteCombinedSlug/_jobSlug/embed.vue' /* webpackChunkName: "pages/business/_businessSiteCombinedSlug/_jobSlug/embed" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/business",
    component: _12614761,
    children: [{
      path: "",
      component: _01cdfdb8,
      name: "business-index"
    }, {
      path: ":businessSiteCombinedSlug",
      component: _46784670,
      name: "business-index-businessSiteCombinedSlug"
    }]
  }, {
    path: "/datenschutz",
    component: _82302116,
    name: "datenschutz"
  }, {
    path: "/impressum",
    component: _2369e349,
    name: "impressum"
  }, {
    path: "/business/embed",
    component: _22efdc30,
    name: "business-embed"
  }, {
    path: "/root/:storyblok?",
    component: _4c3a463b,
    name: "root-storyblok"
  }, {
    path: "/business/:businessSiteCombinedSlug/:jobSlug",
    component: _1f5e74f4,
    name: "business-businessSiteCombinedSlug-jobSlug"
  }, {
    path: "/business/:businessSiteCombinedSlug/:jobSlug?/embed",
    component: _6710dfe6,
    name: "business-businessSiteCombinedSlug-jobSlug-embed"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
