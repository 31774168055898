export const Avatar = () => import('../../components/oabatify/Avatar.vue' /* webpackChunkName: "components/avatar" */).then(c => wrapFunctional(c.default || c))
export const Badge = () => import('../../components/oabatify/Badge.vue' /* webpackChunkName: "components/badge" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/oabatify/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/oabatify/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const Col = () => import('../../components/oabatify/Col.vue' /* webpackChunkName: "components/col" */).then(c => wrapFunctional(c.default || c))
export const Container = () => import('../../components/oabatify/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../components/oabatify/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const Dialog = () => import('../../components/oabatify/Dialog.vue' /* webpackChunkName: "components/dialog" */).then(c => wrapFunctional(c.default || c))
export const Divider = () => import('../../components/oabatify/Divider.vue' /* webpackChunkName: "components/divider" */).then(c => wrapFunctional(c.default || c))
export const ExpansionPanel = () => import('../../components/oabatify/ExpansionPanel.vue' /* webpackChunkName: "components/expansion-panel" */).then(c => wrapFunctional(c.default || c))
export const ExpansionPanelContent = () => import('../../components/oabatify/ExpansionPanelContent.vue' /* webpackChunkName: "components/expansion-panel-content" */).then(c => wrapFunctional(c.default || c))
export const ExpansionPanelHeader = () => import('../../components/oabatify/ExpansionPanelHeader.vue' /* webpackChunkName: "components/expansion-panel-header" */).then(c => wrapFunctional(c.default || c))
export const ExpansionPanels = () => import('../../components/oabatify/ExpansionPanels.vue' /* webpackChunkName: "components/expansion-panels" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../components/oabatify/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const Lazy = () => import('../../components/oabatify/Lazy.vue' /* webpackChunkName: "components/lazy" */).then(c => wrapFunctional(c.default || c))
export const List = () => import('../../components/oabatify/List.vue' /* webpackChunkName: "components/list" */).then(c => wrapFunctional(c.default || c))
export const ListItem = () => import('../../components/oabatify/ListItem.vue' /* webpackChunkName: "components/list-item" */).then(c => wrapFunctional(c.default || c))
export const ListItemGroup = () => import('../../components/oabatify/ListItemGroup.vue' /* webpackChunkName: "components/list-item-group" */).then(c => wrapFunctional(c.default || c))
export const ListItemTitle = () => import('../../components/oabatify/ListItemTitle.vue' /* webpackChunkName: "components/list-item-title" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/oabatify/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const OImg = () => import('../../components/oabatify/OImg.vue' /* webpackChunkName: "components/o-img" */).then(c => wrapFunctional(c.default || c))
export const OSwitch = () => import('../../components/oabatify/OSwitch.vue' /* webpackChunkName: "components/o-switch" */).then(c => wrapFunctional(c.default || c))
export const OTextarea = () => import('../../components/oabatify/OTextarea.vue' /* webpackChunkName: "components/o-textarea" */).then(c => wrapFunctional(c.default || c))
export const Overlay = () => import('../../components/oabatify/Overlay.vue' /* webpackChunkName: "components/overlay" */).then(c => wrapFunctional(c.default || c))
export const Picture = () => import('../../components/oabatify/Picture.vue' /* webpackChunkName: "components/picture" */).then(c => wrapFunctional(c.default || c))
export const ProgressCircular = () => import('../../components/oabatify/ProgressCircular.vue' /* webpackChunkName: "components/progress-circular" */).then(c => wrapFunctional(c.default || c))
export const ProgressLinear = () => import('../../components/oabatify/ProgressLinear.vue' /* webpackChunkName: "components/progress-linear" */).then(c => wrapFunctional(c.default || c))
export const Row = () => import('../../components/oabatify/Row.vue' /* webpackChunkName: "components/row" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/oabatify/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const Snackbar = () => import('../../components/oabatify/Snackbar.vue' /* webpackChunkName: "components/snackbar" */).then(c => wrapFunctional(c.default || c))
export const Spacer = () => import('../../components/oabatify/Spacer.vue' /* webpackChunkName: "components/spacer" */).then(c => wrapFunctional(c.default || c))
export const SpeedDial = () => import('../../components/oabatify/SpeedDial.vue' /* webpackChunkName: "components/speed-dial" */).then(c => wrapFunctional(c.default || c))
export const Tag = () => import('../../components/oabatify/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c))
export const TextField = () => import('../../components/oabatify/TextField.vue' /* webpackChunkName: "components/text-field" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../components/oabatify/Tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const VContainer = () => import('../../components/oabatify/VContainer.vue' /* webpackChunkName: "components/v-container" */).then(c => wrapFunctional(c.default || c))
export const TransitionExpand = () => import('../../components/oabatify/transition/TransitionExpand.vue' /* webpackChunkName: "components/transition-expand" */).then(c => wrapFunctional(c.default || c))
export const TransitionFade = () => import('../../components/oabatify/transition/TransitionFade.vue' /* webpackChunkName: "components/transition-fade" */).then(c => wrapFunctional(c.default || c))
export const TransitionScrollYReverse = () => import('../../components/oabatify/transition/TransitionScrollYReverse.vue' /* webpackChunkName: "components/transition-scroll-y-reverse" */).then(c => wrapFunctional(c.default || c))
export const TransitionSlideYReverse = () => import('../../components/oabatify/transition/TransitionSlideYReverse.vue' /* webpackChunkName: "components/transition-slide-y-reverse" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
