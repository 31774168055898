
import { mdiGoogleMaps, mdiChevronRight } from '@mdi/js'

export default {
  name: "GoogleButton",
  props: {
    latitude: {
      type: Number,
      required: true,
    },
    longitude: {
      type: Number,
      required: true,
    },
  },
  icons: {
    mdiGoogleMaps,
    mdiChevronRight,
  },
  computed: {
    url() {
      return `https://www.google.com/maps/search/?api=1&query=${this.latitude},${this.longitude}`
    },
  },
}
