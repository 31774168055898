
import { VImg } from "vuetify/lib/components"
export default {
    name: "OImg",
    components: { VImg },
    props: {
        alt: {
            default: null,
            type: String,
            required: true,
        },
        src: {
            required: true,
            type: [Object, String],
        },
    },
    computed: {
        imageSource() {
            return this.src?.src || this.src
        },
    },
}
