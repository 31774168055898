
import { DEFAULT_THEME_LIGHT } from "@/helpers/themesHelper"
import { contrast, luminance } from "@/helpers/colors"
import { hashString } from "@/helpers/utils"
import { computed } from "vue"

export default {
    name: "ThemeProvider",
    props: {
        /** Theme Object */
        theme: {
            type: Object,
            default: null,
        },
        /** Only provides theme object for injection */
        provideOnly: {
            type: Boolean,
            default: false,
        },
        /** Sets theme globally, overrides other root themes */
        root: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        id() {
            const hash = hashString(JSON.stringify(this.internalTheme))
            return `theme-${hash}`
        },
        internalTheme() {
            return this.theme || DEFAULT_THEME_LIGHT
        },
        style() {
            const theme = this.internalTheme
            const style = [
                ...this.genFull("buttonPrimary", theme.buttonPrimary || DEFAULT_THEME_LIGHT.buttonPrimary),
                ...this.genFull("buttonSecondary", theme.buttonSecondary || DEFAULT_THEME_LIGHT.buttonSecondary),
                ...this.genFull("backgroundPrimary", theme.backgroundPrimary || DEFAULT_THEME_LIGHT.backgroundPrimary),
                ...this.genFull(
                  "backgroundSecondary",
                    theme.backgroundSecondary
                ),
                ...this.genFull("accentPrimary", theme.accentText || DEFAULT_THEME_LIGHT.accentText),
                this.genVars("headlinePrimary", this.getHeadlineColors(theme.headline, theme.backgroundPrimary || DEFAULT_THEME_LIGHT.backgroundPrimary)),
                this.genVars("headlineSecondary", this.getHeadlineColors(theme.headline, theme.backgroundSecondary || DEFAULT_THEME_LIGHT.backgroundSecondary)),
            ].join("")
            if (this.root) return `body {${style}}`
            return `#${this.id} {${style}}`
        },
        isDark() {
            return this.internalTheme.config?.mode === "dark"
        },
    },
    methods: {
        /** Determines if text is white/black on a given background(s)
         * @param {String|Array} backgroundColors background colors to check
         * @param {Number} rW weight for the red channel
         * @param {Number} gW weight for the green channel
         * @param {Number} bW weight for the blue channel */
        isTextDark(backgroundColors, rW = 0.299, gW = 0.587, bW = 0.114) {
            if (!backgroundColors) {
                return false
            }
            if (typeof backgroundColors === "string")
                backgroundColors = [backgroundColors]
            const thresholds = backgroundColors.map((color) => {
                const components = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
                    .exec(color)
                    .map((el) => parseInt(el, 16))
                return (
                    components[1] * rW + components[2] * gW + components[3] * bW
                )
            })
            return (
                thresholds.reduce((a, b) => a + b, 0) / thresholds.length > 130
            )
        },
        getTextColor(backgroundColors) {
            return this.isTextDark(backgroundColors) ? "#000000" : "#ffffff"
        },
        getTextBlend(backgroundColors) {
            if (this.isDark)
                return this.isTextDark(backgroundColors) ? "darken" : "lighten"
            return this.isTextDark(backgroundColors) ? "lighten" : "darken"
        },
        /** Calculates the headline colors with a good contrast
         * @param {Array} headlineColors array of headline colors in hex format (2 colors)
         * @param {String} backgroundColor color of the background where the headline appears (in hex format)
         * @return {Array|String} */
        getHeadlineColors(headlineColors, backgroundColor) {
          const luminance1 = luminance(headlineColors[0])
          const luminance2 = luminance(headlineColors[1])
          const luminance3 = luminance(backgroundColor)

          const contrast1 = contrast(luminance1, luminance3)
          const contrast2 = contrast(luminance2, luminance3)

          const hasGoodContrast = contrast1 + contrast2 >= 4
          if (hasGoodContrast) return headlineColors
          return this.getTextColor(backgroundColor)

        },
        /** Generates variable string, if multiple values, generates index variables */
        genVars(type, values) {
            if (typeof values === "string") {
                return `--${type}: ${values};`
            }
            let s = ""
            for (let index = 0; index < values.length; index += 1) {
                const hex = values[index]
                s += `--${type}-${index}: ${hex};`
            }
            return s
        },
        /** Generates main, text and blend variable */
        genFull(name, style) {
            let flatBtnText = this.getTextColor(style)
            if (this.isDark) {
                flatBtnText = this.isTextDark(style) ? "#ffffff" : "#000000" // Inverted text color for dark mode buttons
            }
            return [
                this.genVars(name, style),
                this.genVars(`${name}-text`, this.getTextColor(style)),
                this.genVars(`${name}-text--flat`, flatBtnText),
                this.genVars(`${name}-textBlend`, this.getTextBlend(style)),
            ]
        },
    },
    head() {
        if (this.root) {
            return {
                style: [
                    { hid: "theme", cssText: this.style, type: "text/css" },
                ],
            }
        }
        return {
            style: [{ hid: this.id, cssText: this.style, type: "text/css" }],
        }
    },
    provide() {
        return {
            theme: computed(() => this.internalTheme),
            themeIsTextDark: this.isTextDark,
        }
    },
    render(h) {
        if (this.provideOnly || this.root) {
            if (this.$slots.default.length > 1) {
                /** Avoid error from having multiple root elements */
                return h(
                    "div",
                    {
                        class: "theme-provider",
                    },
                    [this.$slots.default]
                )
            }
            return this.$slots.default
        }
        return h(
            "div",
            {
                attrs: { id: this.id },
                class: "theme-provider",
            },
            [this.$slots.default]
        )
    },
}
