
export default {
    name: "Container",
    props: {
        mobile: {
            type: Boolean,
            default: false,
        },
        fullwidth: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        classes() {
            return {
                "oabatify-container": !this.fullwidth,
                "oabatify-container--mobile": this.mobile,
            }
        },
    },
}
