import { getApp } from "@/helpers/context"
/**
 * @param {Number} size File size from large (1) to small (3)
 */
export function getImage(image, size = 3, format = null) {
    let fileEnding = format || supportsWebp() ? "webp" : "jpg"
    const returnImage = {}

    if (supportsWebp()) {
        returnImage.src = require("@/assets/images/placeholder.webp")
    } else {
        returnImage.src = require("@/assets/images/placeholder.jpg")
    }

    if (!image) return returnImage /* Use Placeholder */
    if (!image.file_path &&!image.urls && image.local) return { src: image.local } /* Use local file */
    if ((image.file_path && image.file_name) || image.urls) {
        const fileSuffix = `_${size}.${fileEnding}`
        const placeholderSuffix = `_p.${fileEnding}`
        if (image.urls && image.urls[fileSuffix]) {
              returnImage.src = image.urls[fileSuffix]
              returnImage.placeholder = image.urls[placeholderSuffix]
        }
        if (image.is_private === true) {
            returnImage.src = image.urls[fileSuffix]
            returnImage.placeholder = image.urls[placeholderSuffix]
        } else {
            returnImage.src = image.file_path + image.file_name + fileSuffix
            returnImage.placeholder =
                image.file_path + image.file_name + placeholderSuffix
        }
    }
    return returnImage
}

export function getOGImage(image) {
    const baseURL = getApp().$config.baseURL
    if (image?.urls["_1.jpg"]) {
        return image.urls["_1.jpg"]
    }
    if (!image?.file_path || !image?.file_name) {
        return baseURL + require("@/static/logo.png")
    }
    return `${baseURL}${image.file_path}${image.file_name}_1.jpg`
}

function supportsWebp() {
    return true // Check to be created
}
