let context
export default {
    setContext(ctx) {
        context = ctx
    }
}
export function getContext() {
    return context
}

export function getApp() {
    return context.app
}

export function getRouter() {
    return context.app.router
}

export function getRoute() {
    return context.route
}