
import { Modal } from "@/components/base"
import { mdiClose } from "@mdi/js"
export default {
    name: "ChatMessageHandler",
    icons: { mdiClose },
    components: {
        Modal,
        ChatMessageForm: () => import("@/components/chat/form/ChatMessageForm"),
        ChatApplyForm: () => import("@/components/chat/form/ChatApplyForm"),
    },
    data: () => ({
        component: null,
        componentProps: {},
    }),
    methods: {
        onClose() {
            this.component = null
        },
        /** Called from plugin */
        open(component, props) {
            this.component = component
            this.componentProps = props
        },
    },
}
