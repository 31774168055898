
import linkable from "@/mixins/linkable"

export default {
    name: "Button",
    mixins: [linkable],
    props: {
        /** Route string or object */
        to: {
            type: [String, Object],
            required: false,
        },
        /**
         * The size of the button
         * @values tiny, small, regular, large, x-large
         */
        size: {
            type: String,
            default: "regular",
            validator: (val) =>
                ["tiny", "small", "regular", "large", "x-large"].includes(val),
        },
        /**  Makes the button a circle */
        round: {
            type: Boolean,
            default: false,
        },
        /** Disables interaction */
        disabled: {
            type: Boolean,
            default: false,
        },
        /** Shows loading animation */
        loading: {
            type: Boolean,
            default: false,
        },
        /** Makes the button full width */
        block: {
            type: Boolean,
            default: false,
        },
        /** v-ripple effect on the button */
        ripple: {
            type: Boolean,
            default: true,
        },
        /** Flat design variant */
        flat: {
            type: Boolean,
            default: false,
        },
        /** Design color, requires ThemeProvider */
        design: {
            type: String,
            default: "primary",
            validator: (val) =>
                [
                    "primary",
                    "secondary",
                    "background-primary",
                    "background-secondary",
                    "accent",
                    "transparent"
                ].includes(val),
        },
    },
    emits: ["click"],
    computed: {
        classes() {
            return {
                "btn": true,
                ["btn--" + this.size]: true,
                ["btn--" + this.design]: true,
                "btn--flat": this.flat,
                "btn--block": this.block,
                "btn--round": this.round,
                "btn--disabled cursor-default": this.disabled,
                "btn--loading": this.loading,
            }
        },
        listeners() {
            return { click: this.onClick, ...this.$listeners }
        },
    },
    methods: {
        onClick() {
            this.$emit("click")
            this.$el.blur()
        },
    },
}
