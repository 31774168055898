

export default {
  name: "ThemeIcon",
  props: {
    design: {
      type: String,
      default: "primary",
      validator: (val) =>
        [
          "primary",
          "primary-text",
          "secondary",
          "secondary-text",
          "background-primary",
          "background-primary-text",
          "background-secondary",
          "background-secondary-text",
          "accent",
          "accent-text",
        ].includes(val),
    },
  },
}
