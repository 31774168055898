import { getContext } from "@/helpers/context"
export function truncate(text, max) {
    if (!text) return ""
    return text.substr(0, max - 1) + (text.length > max ? "..." : "")
}


export function getContentBlocks(contentBlocks) {
    try {
        const blocks = contentBlocks[0].data.items
        return blocks.map((block) => ({
            text: block.data.text,
            headline: block.data.headline,
        }))
    } catch {
        return []
    }
}

export function getFullBaseURL() {
    if (process.client) {
        return window.location.origin
    } else {
        const req = getContext().req
        const protocol = req.headers['x-forwarded-proto'] || 'http'
        return `${protocol}://${req.headers.host}`
    }
}