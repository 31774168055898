export function stringToRGB(
    string,
    brightness = 0.8,
    saturation = 0.7,
    from = 130,
    to = 300
) {
    const md5String = tinyHash(string)
    const md5ShiftIdx = 3
    const hsv = rgb2hsv(
        parseInt(
            md5String.slice(0 + md5ShiftIdx, 2 + md5ShiftIdx),
            16
        ) / 255,
        parseInt(
            md5String.slice(2 + md5ShiftIdx, 4 + md5ShiftIdx),
            16
        ) / 255,
        parseInt(
            md5String.slice(4 + md5ShiftIdx, 6 + md5ShiftIdx),
            16
        ) / 255
    )
    const h = mapRange(hsv[0], 0, 360, from, to)
    const rgb = hsv2rgb(h, saturation, brightness)
    return `rgb(${Math.round(rgb[0] * 255)}, ${Math.round(
        rgb[1] * 255
    )}, ${Math.round(rgb[2] * 255)})`
}

export function rgb2hsv(r, g, b) {
    const v = Math.max(r, g, b)
    const c = v - Math.min(r, g, b)
    const h =
        c &&
        (v == r
            ? (g - b) / c
            : v == g
                ? 2 + (b - r) / c
                : 4 + (r - g) / c)
    return [60 * (h < 0 ? h + 6 : h), v && c / v, v]
}
export function hsv2rgb(h, s, v) {
    const f = (n, k = (n + h / 60) % 6) =>
        v - v * s * Math.max(Math.min(k, 4 - k, 1), 0)
    return [f(5), f(3), f(1)]
}

export function hexToRGB(h) {
    let r = 0
    let g = 0
    let b = 0

    // 3 digits
    if (h.length === 4) {
        r = "0x" + h[1] + h[1]
        g = "0x" + h[2] + h[2]
        b = "0x" + h[3] + h[3]

        // 6 digits
    } else if (h.length === 7) {
        r = "0x" + h[1] + h[2]
        g = "0x" + h[3] + h[4]
        b = "0x" + h[5] + h[6]
    }

    return { r: +r, g: +g, b: +b }
}

export function contrast(luminance1, luminance2) {
  const lighterLuminance = Math.max(luminance1, luminance2)
  const darkerLuminance = Math.min(luminance1, luminance2)
  return (lighterLuminance + 0.05) / (darkerLuminance + 0.05)
}

export function luminance(color) {
  const hexColor = color.replace("#", "")
  const red = parseInt(hexColor.substr(0, 2), 16)
  const green = parseInt(hexColor.substr(2, 2), 16)
  const blue = parseInt(hexColor.substr(4, 2), 16)

  const redLuminance = channelLuminance(red)
  const greenLuminance = channelLuminance(green)
  const blueLuminance = channelLuminance(blue)

  return 0.2126 * redLuminance + 0.7152 * greenLuminance + 0.0722 * blueLuminance
}

// helpers
function channelLuminance(channel) {
  return  channel / 255 < 0.03928 ? channel / 255 / 12.92 : Math.pow((channel / 255 + 0.055) / 1.055, 2.4)
}

function mapRange(x, in_min, in_max, out_min, out_max) {
    return (
        ((x - in_min) * (out_max - out_min)) / (in_max - in_min) +
        out_min
    )
}

function tinyHash(str) {
    let hash = str.length * 747796405 + 1
    for (let i = 0; i < str.length; i++) {
        const rng = str.charCodeAt(i)
        hash = ((rng >> ((rng >> 28) + 4)) ^ rng) * 277803737
    }
    if (hash < 0) {
        hash = hash * -1
    }
    return hash.toString(16)
}
