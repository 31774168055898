import Vue from 'vue'
import VueGtag from 'vue-gtag'
import { bootstrap } from 'vue-gtag'
const DISABLED = process.env.NODE_ENV !== 'production'

let isSetup = false

export default function ({ app }) {
  const isAllowed = app.$consent.performance
  Vue.use(VueGtag, {
    config: {
      id: 'G-9T193THYVV',
      params: {
        send_page_view: true
      }
    },
    bootstrap: isAllowed && !DISABLED,
  })
  if (isAllowed) return isSetup = true

  app.mixins = app.mixins || []
  app.mixins.push({
    watch: {
      '$consent.performance'(newVal) {
        if (newVal) {
          if (isSetup || DISABLED) return
          isSetup = true
          bootstrap()
        }
      }
    },
  })
}
