// CONSTANTS
const WHITE_RGB = [255, 255, 255]
const GRAY_RGB = [128, 128, 128]
const BLACK_RGB = [0, 0, 0]
const NUM_OF_TAGS = 5

// DEFAULT THEMES
const TAG_LIGHT = {
    brightness: 1,
    saturation: 0.6,
    hueFrom: 150,
    hueTo: 350,
}
const TAG_DARK = {
    brightness: 0.85,
    saturation: 0.8,
    hueFrom: 150,
    hueTo: 300,
}

export const DEFAULT_THEME_LIGHT = {
    nav: "#ffffff",
    backgroundPrimary: "#ffffff",
    backgroundSecondary: "#ffffff",
    backgroundTertiary: "#ffffff",
    headline: ["#fe2d3e", "#2913e4"],
    buttonPrimary: ["#ff6e91", "#3643ef"],
    buttonSecondary: "#000000",
    accentText: "#000000",
    tags: Array(5).fill(TAG_LIGHT),
}
export const DEFAULT_THEME_DARK = {
    nav: "#000000",
    backgroundPrimary: "#000000",
    backgroundSecondary: "#000000",
    backgroundTertiary: "#000000",
    headline: ["#fe2d3e", "#2913e4"],
    buttonPrimary: ["#ff6e91", "#3643ef"],
    buttonSecondary: "#ffffff",
    accentText: "#ffffff",
    tags: Array(5).fill(TAG_DARK),
}

// HELPERS
function getLuminosity(r, g, b) {
    return Math.sqrt(0.299 * r * r + 0.587 * g * g + 0.114 * b * b)
}
function compareLuminosity(pixel1, pixel2, ascending = false) {
    const lum1 = getLuminosity(...pixel1)
    const lum2 = getLuminosity(...pixel2)
    return ascending ? lum1 - lum2 : lum2 - lum1
}

function channel2hex(channel) {
    const hexadecimal = parseInt(channel).toString(16)
    return hexadecimal.length === 1 ? "0" + hexadecimal : hexadecimal
}
function rgb2hex(red, green, blue) {
    return "#" + channel2hex(red) + channel2hex(green) + channel2hex(blue)
}

function hex2rgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
    ]
}

function clamp(num, min, max) {
    return Math.min(Math.max(num, min), max)
}

function rgb2hsv(r, g, b) {
    const v = Math.max(r, g, b)
    const c = v - Math.min(r, g, b)
    const h =
        c &&
        (v === r ? (g - b) / c : v === g ? 2 + (b - r) / c : 4 + (r - g) / c)

    return [60 * (h < 0 ? h + 6 : h), v && c / v, v]
}

function hsv2rgb(h, s, v) {
    const f = (n, k = (n + h / 60) % 6) =>
        v - v * s * Math.max(Math.min(k, 4 - k, 1), 0)
    return [
        Math.round(f(5) * 255),
        Math.round(f(3) * 255),
        Math.round(f(1) * 255),
    ]
}

function hsv2hex(h, s, v) {
    return rgb2hex(...hsv2rgb(h, s, v))
}

function colorDistance(pixel1, pixel2) {
    const rMean = (pixel1[0] + pixel2[0]) / 2
    const rDiff = pixel1[0] - pixel2[0]
    const bDiff = pixel1[1] - pixel2[1]
    const gDiff = pixel1[2] - pixel2[2]
    return Math.sqrt(
        ((512 + rMean) * rDiff * rDiff) / 128 +
            4 * gDiff * gDiff +
            ((767 - rMean) * bDiff * bDiff) / 128
    )
}

function getGradient(inputColor, dark = false) {
    const eps_hue = 0.07
    const del_value = dark ? 1.1 : 1.0
    const eps_value = 0.1
    const eps_sat = 1.3

    const c_hsv = rgb2hsv(...inputColor)

    const c_start = [
        c_hsv[0] - ((eps_hue * 360) % 360),
        clamp(c_hsv[1] * eps_sat, 0.0, 1.0),
        clamp((c_hsv[2] / 255) * del_value - eps_value, 0.0, 1.0),
    ]

    const c_end = [
        c_hsv[0] + ((eps_hue * 360) % 360),
        c_hsv[1],
        clamp((c_hsv[2] / 255) * del_value + eps_value, 0.0, 1.0),
    ]
    return [hsv2hex(...c_start), hsv2hex(...c_end)]
}

// Being used on sorted main different colors, so always have >= 3 sorted by luminosity colors
function getTagColors(colors) {
    function fillArray(filler, repeats) {
        return [].concat.apply(
            [],
            Array.from({ length: repeats }, () => filler)
        )
    }

    // Remove backgroundPrimary color, leave only colors which are far enough from white, black and backgroundPrimary
    let colorsForTags = colors
        .slice(1)
        .filter(
            (color) =>
                colorDistance(color, colors[0]) > 300 &&
                colorDistance(color, WHITE_RGB) > 150 &&
                colorDistance(color, BLACK_RGB) > 150
        )

    const isBackgroundPrimaryDark =
        colorDistance(colors[0], WHITE_RGB) >
        colorDistance(colors[0], BLACK_RGB)

    // If no such colors exist, add shades of black or white
    if (colorsForTags.length === 0)
        isBackgroundPrimaryDark
            ? colorsForTags.push(WHITE_RGB)
            : colorsForTags.push(BLACK_RGB)

    colorsForTags = fillArray(colorsForTags, NUM_OF_TAGS).slice(0, 5)

    colorsForTags = colorsForTags.map((color) => rgb2hsv(...color))

    function calcBrightness(normalizedBrightness) {
        return isBackgroundPrimaryDark
            ? Math.min(1, normalizedBrightness + 0.2)
            : Math.max(0, normalizedBrightness - 0.2)
    }

    return colorsForTags.map((color) => {
        return {
            brightness: calcBrightness(color[2] / 255),
            saturation: color[1],
            hueFrom: color[0],
            hueTo: color[0],
        }
    })
}

/** Derives them most dominant colors from the image using K-means algorithm
 * @param {String} url url to the image
 * @param {Number} k positive number of output colors
 * @param {number} kickTreshhold percentage threshold for kicking out unpopular colors, if set to null, then output has all k colors
 * @returns {Promise} array of RGB colors */
export async function kMeansPallete(url, k = 5, kickTreshhold = null) {
    const MAX_ITERATIONS = 50
    const EPS = 0.001

    function loadImage(url) {
        return new Promise((resolve) => {
            const image = new Image()
            image.onload = () => resolve(image)
            image.src = url
        })
    }

    function getImageContext(img) {
        const canvas = document.createElement("canvas")
        const context = canvas.getContext("2d")
        canvas.width = img.naturalWidth
        canvas.height = img.naturalHeight
        context.drawImage(img, 0, 0)
        return { context, width: img.naturalWidth, height: img.naturalHeight }
    }

    function getImageBitmap({ context, width, height }) {
        return context.getImageData(1, 1, width, height).data
    }

    function sliceIntoChunks(arr, chunksNum) {
        const size = Math.ceil(arr.length / chunksNum)
        return Array.from({ length: chunksNum }, (v, i) =>
            arr.slice(i * size, i * size + size)
        )
    }

    // Select initial centroids
    function getNaiveShardingCentroids(img, k) {
        let imgWithLuminosities = []
        // Skip alpha channel
        for (let i = 0; i < img.length; i += 4)
            imgWithLuminosities.push([
                img[i],
                img[i + 1],
                img[i + 2],
                getLuminosity(img[i], img[i + 1], img[i + 2]),
            ])
        imgWithLuminosities = imgWithLuminosities.sort(
            (pixel1, pixel2) => pixel1[3] - pixel2[3]
        )
        return sliceIntoChunks(imgWithLuminosities, k)
            .map((chunk) => getPixelsMean(chunk))
            .map((preCentroid) =>
                preCentroid.slice(0, 3).map((channel) => parseInt(channel))
            )
    }

    // Get labels (associated centroids) for each point
    function getLabels(img, centroids) {
        const labels = {}
        for (let c = 0; c < centroids.length; c++) {
            labels[c] = {
                points: [],
                centroid: centroids[c],
            }
        }
        for (let i = 0; i < img.length; i += 4) {
            const curPixel = [img[i], img[i + 1], img[i + 2]]
            let closestCentroid
            let closestCentroidIndex = 0
            let prevDistance = 255 * 255 * 3 + 1
            for (let j = 0; j < centroids.length; j++) {
                const centroid = centroids[j]
                if (j === 0) {
                    closestCentroid = centroid
                    closestCentroidIndex = j
                    prevDistance = colorDistance(curPixel, closestCentroid)
                } else {
                    // get distance:
                    const distance = colorDistance(curPixel, centroid)
                    if (distance < prevDistance) {
                        prevDistance = distance
                        closestCentroid = centroid
                        closestCentroidIndex = j
                    }
                }
            }
            // add point to centroid labels:
            labels[closestCentroidIndex].points.push(curPixel)
        }
        return labels
    }

    function getPixelsMean(pixelList) {
        const sums = pixelList.reduce((acc, cur) =>
            acc.map((channel, index) => channel + cur[index])
        )
        return sums.map((channel) => channel / pixelList.length)
    }

    function recalculateCentroids(img, labels) {
        let newCentroid
        const newCentroidList = []
        for (const k in labels) {
            const centroidGroup = labels[k]
            if (centroidGroup.points.length > 0)
                newCentroid = getPixelsMean(centroidGroup.points)
            else newCentroid = getNaiveShardingCentroids(img, 1)[0]
            newCentroidList.push(newCentroid)
        }
        return newCentroidList
    }

    function compareCentroids(centroid1, centroid2) {
        return centroid1.every(
            (centroidEl1, index) =>
                Math.abs(centroidEl1 - centroid2[index]) < EPS
        )
    }

    function shouldStop(oldCentroids, centroids, iterations) {
        if (!oldCentroids || !oldCentroids.length) return false
        return (
            iterations > MAX_ITERATIONS ||
            oldCentroids.every((oldCentroid, index) =>
                compareCentroids(oldCentroid, centroids[index])
            )
        )
    }

    function calcKMeans(img, k) {
        let oldCentroids = []
        let labels
        let centroids = getNaiveShardingCentroids(img, k)
        let iterations = 0

        getNaiveShardingCentroids(img, 5)
        while (!shouldStop(oldCentroids, centroids, iterations)) {
            oldCentroids = [...centroids]
            labels = getLabels(img, centroids)
            centroids = recalculateCentroids(img, labels)
            iterations++
        }

        let colors = centroids.map((color) =>
            color.map((channel) => Math.round(channel))
        )

        if (kickTreshhold) {
            const numPixels = img.length / 4
            const colorsToRemoveIds = []
            Object.entries(labels).forEach(([key, value]) => {
                if ((value.points.length / numPixels) * 100 < kickTreshhold)
                    colorsToRemoveIds.push(parseInt(key))
            })
            colors = colors.filter(
                (color, index) => !colorsToRemoveIds.includes(index)
            )
        }

        return {
            colors,
            clusterSizes: Object.values(labels).map((label) =>
                ((label.points.length / img.length) * 4 * 100).toFixed(2)
            ),
        }
    }

    return loadImage(url)
        .then(getImageContext)
        .then(getImageBitmap)
        .then((img) => calcKMeans(img, k))
}

/** Generates theme object from colors
 * @param {object} palette contains colors and corresponding clusterSizes (color areas)
 * @param {Boolean} dark dark mode
 * @returns theme object */
export function genTheme(palette, dark = false) {
    function compareEdges(subgraphs, colorObject) {
        const subgraphIDs = []
        const newSubgraphs = []
        subgraphs.forEach((subgraph, index) => {
            const farColorObjects = subgraph.filter(
                (subgraphColorObject) =>
                    colorDistance(
                        subgraphColorObject.color,
                        colorObject.color
                    ) > 250
            )
            // If all colors are far enough, add subgraph index and push colorObject to this subgraph later
            if (farColorObjects.length === subgraph.length)
                subgraphIDs.push(index)
            // Else extract all far colors and make new subgraph with the current colorObject
            else newSubgraphs.push([...farColorObjects, colorObject])
        })
        if (subgraphIDs.length !== 0)
            subgraphIDs.forEach((subgraphID) =>
                subgraphs[subgraphID].push(colorObject)
            )
        if (newSubgraphs.length !== 0) subgraphs.push(...newSubgraphs)
    }

    function getMaxSubgraph(colorObjects) {
        function getSubgraphArea(subgraph) {
            return subgraph.reduce(
                (acc, colorObject) => acc + colorObject.clusterSize,
                0
            )
        }

        const subgraphs = [[colorObjects[0]]]
        colorObjects
            .slice(1)
            .forEach((colorObject) => compareEdges(subgraphs, colorObject))
        // Pick the largest set of colors, if multiple exist pick with the biggest areas
        let largestSubgraph = subgraphs[0]
        for (let i = 1; i < subgraphs.length; i++)
            if (
                subgraphs[i].length > largestSubgraph.length ||
                (subgraphs[i].length === largestSubgraph.length &&
                    getSubgraphArea(largestSubgraph) <
                        getSubgraphArea(subgraphs[i]))
            )
                largestSubgraph = subgraphs[i]
        return largestSubgraph
    }

    function getMainColorThreshold(maxClusterSize) {
        switch (true) {
            case maxClusterSize > 70:
                return 2.2
            case maxClusterSize > 60:
                return 2.6
            case maxClusterSize > 50:
                return 3.0
            case maxClusterSize > 40:
                return 3.5
            default:
                return 5
        }
    }

    function addColors(colorObjects) {
        if (colorObjects.length > 2) return colorObjects

        if (colorObjects.length === 1) {
            colorObjects.push({
                color:
                    dark &&
                    colorDistance(BLACK_RGB, colorObjects[0].color) > 200
                        ? BLACK_RGB
                        : WHITE_RGB,
            })
            return colorObjects
        }

        const maxDistanceToBlack = Math.max(
            colorDistance(BLACK_RGB, colorObjects[0].color),
            colorDistance(BLACK_RGB, colorObjects[1].color)
        )
        const maxDistanceToWhite = Math.max(
            colorDistance(WHITE_RGB, colorObjects[0].color),
            colorDistance(WHITE_RGB, colorObjects[1].color)
        )

        if (maxDistanceToWhite > maxDistanceToBlack)
            colorObjects.push({ color: WHITE_RGB })
        else colorObjects.push({ color: BLACK_RGB })

        return colorObjects
    }

    // Main
    const innerPalette = JSON.parse(JSON.stringify(palette))
    const colorObjects = innerPalette.colors.map((color, index) => {
        return {
            id: index,
            color,
            clusterSize: parseFloat(innerPalette.clusterSizes[index]),
        }
    })

    const clusterSizeThreshold = getMainColorThreshold(
        colorObjects.reduce(
            (acc, colorObj) => Math.max(acc, colorObj.clusterSize),
            -Infinity
        )
    )

    // get rid of colors with <= 3% of pixels
    const colorObjectsMain = colorObjects.filter(
        (colorObject) => colorObject.clusterSize > clusterSizeThreshold
    )

    let colorObjectsMainDifferent = getMaxSubgraph(colorObjectsMain)
    colorObjectsMainDifferent = addColors(colorObjectsMainDifferent)

    const colorsMainDifferentSorted = colorObjectsMainDifferent
        .map((colorObject) => colorObject.color)
        .sort((color1, color2) => compareLuminosity(color1, color2, dark))
    const colorsMainDifferentProcessed = colorsMainDifferentSorted.slice(0, 4)
    const colorsHex = colorsMainDifferentProcessed.map((color) =>
        rgb2hex(...color)
    )

    const tagColors = getTagColors(colorsMainDifferentSorted)
    const accent = hsv2hex(
        tagColors[0].hueFrom,
        tagColors[0].saturation,
        tagColors[0].brightness
    )
    return {
        nav: colorsHex[0],
        backgroundPrimary: colorsHex[0],
        backgroundSecondary: colorsHex[1],
        buttonPrimary:
            colorsHex.length > 2
                ? colorsHex[2]
                : getGradient(colorsMainDifferentProcessed[0], dark),
        buttonSecondary: colorsHex[0],
        headline: getGradient(
            colorsMainDifferentProcessed[
                colorsMainDifferentProcessed.length - 1
            ],
            dark
        ),
        accentText: accent,
        tags: tagColors,
    }
}

/** Generates missing properties theme properties
 * @param {object} subTheme sub theme object that contains at least backgroundPrimary, backgroundSecondary, buttonPrimary
 * @param {Boolean} dark dark mode */
export function genCustomTheme(subTheme, dark = false) {
    const colors = [
        subTheme.backgroundPrimary,
        subTheme.backgroundSecondary,
        subTheme.buttonPrimary,
    ].map((color) => hex2rgb(color))
    const tagColors = getTagColors(colors, dark)
    const accent = hsv2hex(
        tagColors[0].hueFrom,
        tagColors[0].saturation,
        tagColors[0].brightness
    )
    return {
        nav: subTheme.backgroundPrimary,
        backgroundPrimary: subTheme.backgroundPrimary,
        backgroundSecondary: subTheme.backgroundSecondary,
        buttonPrimary: subTheme.buttonPrimary,
        buttonSecondary: subTheme.backgroundPrimary,
        headline: getGradient(hex2rgb(subTheme.buttonPrimary), dark),
        accentText: accent,
        tags: tagColors,
    }
}

export function areThemesEqual(theme1, theme2) {
    let areThemesEqual = true
    Object.keys(theme1).forEach((key) => {
        if (key !== "config" && key !== "tags")
            if (typeof theme1[key] !== typeof theme2[key]) return false
            else if (typeof theme1[key] === "string") {
                areThemesEqual =
                    areThemesEqual &&
                    theme1[key].toLowerCase() === theme2[key].toLowerCase()
            } else
                areThemesEqual =
                    areThemesEqual &&
                    theme1[key].every(
                        (el, id) =>
                            theme1[key][id].toLowerCase() ===
                            theme2[key][id].toLowerCase()
                    )
    })
    return areThemesEqual
}

export function isThemeDefault(theme) {
    return (
        areThemesEqual(theme, DEFAULT_THEME_LIGHT) ||
        areThemesEqual(theme, DEFAULT_THEME_DARK)
    )
}

// Card gradient generator
class GradientColor {
    constructor(startColor = "", endColor = "", minNum = 0, maxNum = 10) {
        this.setColorGradient = (colorStart, colorEnd) => {
            startColor = getHexColor(colorStart)
            endColor = getHexColor(colorEnd)
        }

        this.setMidpoint = (minNumber, maxNumber) => {
            minNum = minNumber
            maxNum = maxNumber
        }

        this.getColor = (numberValue) => {
            if (numberValue) {
                return (
                    "#" +
                    generateHex(
                        numberValue,
                        startColor.substring(0, 2),
                        endColor.substring(0, 2)
                    ) +
                    generateHex(
                        numberValue,
                        startColor.substring(2, 4),
                        endColor.substring(2, 4)
                    ) +
                    generateHex(
                        numberValue,
                        startColor.substring(4, 6),
                        endColor.substring(4, 6)
                    )
                )
            }
        }

        const generateHex = (number, start, end) => {
            if (number < minNum) {
                number = minNum
            } else if (number > maxNum) {
                number = maxNum
            }

            const midPoint = maxNum - minNum
            const startBase = parseInt(start, 16)
            const endBase = parseInt(end, 16)
            const average = (endBase - startBase) / midPoint
            const finalBase = Math.round(
                average * (number - minNum) + startBase
            )
            return finalBase < 16
                ? "0" + finalBase.toString(16)
                : finalBase.toString(16)
        }

        const getHexColor = (color) => {
            return color.substring(color.length - 6, color.length)
        }
    }
}

class Gradient {
    constructor(
        colorGradients = "",
        maxNum = 10,
        colors = ["", ""],
        intervals = []
    ) {
        const setColorGradient = (gradientColors) => {
            if (gradientColors.length < 2) {
                throw new Error(
                    `setColorGradient should have more than ${gradientColors.length} color`
                )
            } else {
                const increment = maxNum / (gradientColors.length - 1)
                const firstColorGradient = new GradientColor()
                const lower = 0
                const upper = increment
                firstColorGradient.setColorGradient(
                    gradientColors[0],
                    gradientColors[1]
                )
                firstColorGradient.setMidpoint(lower, upper)
                colorGradients = [firstColorGradient]
                intervals = [
                    {
                        lower,
                        upper,
                    },
                ]

                for (let i = 1; i < gradientColors.length - 1; i++) {
                    const gradientColor = new GradientColor()
                    const lower = increment * i
                    const upper = increment * (i + 1)
                    gradientColor.setColorGradient(
                        gradientColors[i],
                        gradientColors[i + 1]
                    )
                    gradientColor.setMidpoint(lower, upper)
                    colorGradients[i] = gradientColor
                    intervals[i] = {
                        lower,
                        upper,
                    }
                }
                colors = gradientColors
            }
        }

        this.setColorGradient = (...gradientColors) => {
            setColorGradient(gradientColors)
            return this
        }

        this.getColors = () => {
            const gradientColorsArray = []
            for (let j = 0; j < intervals.length; j++) {
                const interval = intervals[j]
                const start =
                    interval.lower === 0 ? 1 : Math.ceil(interval.lower)
                const end =
                    interval.upper === maxNum
                        ? interval.upper + 1
                        : Math.ceil(interval.upper)
                for (let i = start; i < end; i++) {
                    gradientColorsArray.push(colorGradients[j].getColor(i))
                }
            }
            return gradientColorsArray
        }

        this.getColor = (numberValue) => {
            if (isNaN(numberValue))
                throw new TypeError(`getColor should be a number`)
            if (numberValue <= 0)
                throw new TypeError(
                    `getColor should be greater than ${numberValue}`
                )
            const toInsert = numberValue + 1
            const segment = maxNum / colorGradients.length
            const index = Math.min(
                Math.floor(Math.max(numberValue, 0) / segment),
                colorGradients.length - 1
            )
            return colorGradients[index].getColor(toInsert)
        }

        this.setMidpoint = (maxNumber) => {
            if (isNaN(maxNumber)) {
                throw new RangeError("midPoint should be a number")
            }
            if (maxNumber <= 0) {
                throw new RangeError(
                    `midPoint must be greater than ${maxNumber}`
                )
            }
            maxNum = maxNumber
            setColorGradient(colors)
            return this
        }
    }
}

/** Generate array of gradient colors from white to black with a given color in the middle
 * @param {Array} colors array of colors, first color, which is far enough from white or black, picked as a middle color
 * @param {Number} num number of colors in the output gradient array
 * @return {Array} array of colors */
export function genGradientArray(colors, num = 8) {
    const middleColor =
        colors?.find(
            (color) =>
                colorDistance(color, WHITE_RGB) > 100 &&
                colorDistance(color, BLACK_RGB) > 100
        ) ?? GRAY_RGB
    const referenceColors = [WHITE_RGB, middleColor, BLACK_RGB].map((color) =>
        rgb2hex(...color)
    )
    return new Gradient()
        .setColorGradient(...referenceColors)
        .setMidpoint(num)
        .getColors()
}
