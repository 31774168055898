
import { mdiContentCopy, mdiCheck } from "@mdi/js"
export default {
    name: "CopyToClipboardText",
    props: {
        textToCopy: {
            type: String,
            default: "",
        },
        placeholderText: {
            type: String,
            default: null,
        },
    },
    icons: {
        mdiContentCopy,
        mdiCheck,
    },
    data: () => ({
        copySuccess: false,
    }),
    computed: {
        displayText() {
            return this.placeholderText ?? this.textToCopy
        },
    },
    methods: {
        copyLinkToClipboard() {
            if (process.browser) {
                if (!navigator.clipboard) {
                    this.iosFallback(this.textToCopy)
                    return
                }
                this.copySuccess = true
                setTimeout(() => {
                    this.copySuccess = false
                }, 2000)
                return navigator.clipboard.writeText(this.textToCopy)
            } else {
                return ""
            }
        },
        iosFallback(text) {
            const isIos = navigator.userAgent.match(/ipad|iphone/i)
            const textarea = document.createElement("textarea")

            // create textarea
            textarea.value = text

            // ios will zoom in on the input if the font-size is < 16px
            textarea.style.fontSize = "20px"
            document.body.appendChild(textarea)

            // select text
            if (isIos) {
                const range = document.createRange()
                range.selectNodeContents(textarea)

                const selection = window.getSelection()
                selection.removeAllRanges()
                selection.addRange(range)
                textarea.setSelectionRange(0, 999999)
            } else {
                textarea.select()
            }

            // copy selection
            document.execCommand("copy")

            // cleanup
            document.body.removeChild(textarea)
        },
    },
}
