export default {
    head() {
        const logo = this.$businessProvider?.business?.logo
        if (!logo) return null
        const href = `${logo.urls[0]}_200_fit.jpg`
        return {
            link: [
                {
                    hid: "favicon",
                    rel: "icon",
                    type: "image/jpeg",
                    href,
                },
                {
                    hid: "shortcut-icon",
                    rel: "icon",
                    type: "image/jpeg",
                    href,
                },
            ],
        }
    },
}
