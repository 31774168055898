export default {
    props: {
        /**
         * Makes the component a link
         * Auto-detects external strings
         */
        to: {
            type: [String, Object],
            default: null,
        },
        /** sets target of a tag */
        target: {
            type: String,
            default: "",
            validator: (val) =>
                ["", "_blank", "_self", "_parent", "_top"].includes(val),
        },
        /** Overrides default rel values (nofollow noopener noreferrer) */
        rel: {
            type: String,
            default: null,
        },
    },
    computed: {
        toRoute() {
            if (this.to && typeof this.to === "object")
                return this.$router.resolve(this.to).href
            return this.to
        },
        elementType() {
            if (!this.toRoute) return "button"
            if (this.isNuxt) return "nuxt-link"
            return "a"
        },
        isNuxt() {
            return this.toRoute && !this.externalUrl
        },
        externalUrl() {
            if (this.target === "_blank") return this.toRoute
            if (this.toRoute?.includes("://")) return this.toRoute
            return undefined
        },
        isOabatUrl() {
            try {
                /* URL returned Object is not persistent, needs fallback */
                const host = new URL(this.externalUrl).hostname
                return host?.includes("oabat.de") || host?.includes("einblick.pro")
            } catch (e) {
                return this.externalUrl?.includes("oabat.de") || this.externalUrl?.includes("einblick.pro")
            }
        },
        relType() {
            if (this.rel !== null) return this.rel
            if (this.externalUrl) {
                if (this.isOabatUrl) return "nofollow"
                return "nofollow noopener noreferrer"
            }
            return undefined
        },
        isExternal() {
            let hostname = ""
            if (process.server) {
                hostname = this.$ssrContext.req.headers.host.replace("www.", "")
            } else {
                hostname = location.host.replace("www.", "")
            }
            return (
                this.toRoute?.includes("://") &&
                !this.toRoute?.includes(hostname) &&
                !this.$config.disbaleCustomDomains
            )
        },
        /** All bindings except elementType */
        $linkable() {
            const linkable = {
                to: this.toRoute,
                target: this.isExternal ? "_blank" : this.target,
                rel: this.relType,
            }
            if (this.externalUrl) {
                linkable.href = this.externalUrl
            }
            return linkable
        },
    },
    methods: {
        /** Returns the needed element type.
         * div by default, others as paramter
         */
        getElementType(element = "div") {
            if (!this.toRoute) return element
            if (this.isNuxt) return "nuxt-link"
            return "a"
        },
    },
}
