import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_144aaae4 from 'nuxt_plugin_plugin_144aaae4' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_dfd39c84 from 'nuxt_plugin_sentryserver_dfd39c84' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_2a5cc036 from 'nuxt_plugin_sentryclient_2a5cc036' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_4cd9b8ec from 'nuxt_plugin_plugin_4cd9b8ec' // Source: ./vuetify/plugin.js (mode: 'all')
import nuxt_plugin_plugin_a9d72e68 from 'nuxt_plugin_plugin_a9d72e68' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_pluginserver_19bdeee8 from 'nuxt_plugin_pluginserver_19bdeee8' // Source: ./nuxt-speedkit/plugin.server.js (mode: 'server')
import nuxt_plugin_pluginclient_97f70540 from 'nuxt_plugin_pluginclient_97f70540' // Source: ./nuxt-speedkit/plugin.client.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_2e7177a2 from 'nuxt_plugin_cookieuniversalnuxt_2e7177a2' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_nuxtuseragent_7d4b4643 from 'nuxt_plugin_nuxtuseragent_7d4b4643' // Source: ./nuxt-user-agent.js (mode: 'all')
import nuxt_plugin_workbox_ef6f2ad4 from 'nuxt_plugin_workbox_ef6f2ad4' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_909e88ae from 'nuxt_plugin_metaplugin_909e88ae' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_axios_1d0b0bfa from 'nuxt_plugin_axios_1d0b0bfa' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_router_38a2fef5 from 'nuxt_plugin_router_38a2fef5' // Source: ./router.js (mode: 'all')
import nuxt_plugin_fullscreenMediaplugin4fed59bb_3e0524f6 from 'nuxt_plugin_fullscreenMediaplugin4fed59bb_3e0524f6' // Source: ./fullscreenMedia.plugin.4fed59bb.js (mode: 'client')
import nuxt_plugin_connectionSpeedpluginServer33f94798_36d6f330 from 'nuxt_plugin_connectionSpeedpluginServer33f94798_36d6f330' // Source: ./connectionSpeed.pluginServer.33f94798.js (mode: 'all')
import nuxt_plugin_connectionSpeedplugin028c064a_2d4d0d4c from 'nuxt_plugin_connectionSpeedplugin028c064a_2d4d0d4c' // Source: ./connectionSpeed.plugin.028c064a.js (mode: 'client')
import nuxt_plugin_oabalyticspluginServer2e79b910_7ef21bb4 from 'nuxt_plugin_oabalyticspluginServer2e79b910_7ef21bb4' // Source: ./oabalytics.pluginServer.2e79b910.js (mode: 'all')
import nuxt_plugin_oabalyticsplugin178e40bb_1c7a88ff from 'nuxt_plugin_oabalyticsplugin178e40bb_1c7a88ff' // Source: ./oabalytics.plugin.178e40bb.js (mode: 'client')
import nuxt_plugin_contextProvider_28644a8a from 'nuxt_plugin_contextProvider_28644a8a' // Source: ../plugins/contextProvider (mode: 'all')
import nuxt_plugin_api_5e4622e4 from 'nuxt_plugin_api_5e4622e4' // Source: ../plugins/api (mode: 'all')
import nuxt_plugin_breakpoint_4e0af832 from 'nuxt_plugin_breakpoint_4e0af832' // Source: ../plugins/breakpoint (mode: 'all')
import nuxt_plugin_images_5d5ea524 from 'nuxt_plugin_images_5d5ea524' // Source: ../plugins/images (mode: 'all')
import nuxt_plugin_staticInjector_018d5e90 from 'nuxt_plugin_staticInjector_018d5e90' // Source: ../plugins/staticInjector (mode: 'all')
import nuxt_plugin_businessdomains_42aa9639 from 'nuxt_plugin_businessdomains_42aa9639' // Source: ../plugins/businessdomains (mode: 'all')
import nuxt_plugin_cookieBanner_7fd495b4 from 'nuxt_plugin_cookieBanner_7fd495b4' // Source: ../plugins/cookieBanner (mode: 'all')
import nuxt_plugin_gtag_0a2cb1e4 from 'nuxt_plugin_gtag_0a2cb1e4' // Source: ../plugins/gtag.js (mode: 'client')
import nuxt_plugin_chatModal_613b9102 from 'nuxt_plugin_chatModal_613b9102' // Source: ../plugins/chatModal (mode: 'client')
import nuxt_plugin_meta_6c5806de from 'nuxt_plugin_meta_6c5806de' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const store = null
  const router = await createRouter(ssrContext, config, { store })

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":"%s","title":"EinBlick","meta":[{"hid":"charset","charset":"UTF-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Geben sie EinBlick"},{"hid":"format","name":"format-detection","content":"telephone=no"}],"link":[{"rel":"preconnect","href":"https:\u002F\u002Fuse.typekit.net","crossorigin":true}],"style":[],"script":[]},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_144aaae4 === 'function') {
    await nuxt_plugin_plugin_144aaae4(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_dfd39c84 === 'function') {
    await nuxt_plugin_sentryserver_dfd39c84(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_2a5cc036 === 'function') {
    await nuxt_plugin_sentryclient_2a5cc036(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_4cd9b8ec === 'function') {
    await nuxt_plugin_plugin_4cd9b8ec(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_a9d72e68 === 'function') {
    await nuxt_plugin_plugin_a9d72e68(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_19bdeee8 === 'function') {
    await nuxt_plugin_pluginserver_19bdeee8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_97f70540 === 'function') {
    await nuxt_plugin_pluginclient_97f70540(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_2e7177a2 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_2e7177a2(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtuseragent_7d4b4643 === 'function') {
    await nuxt_plugin_nuxtuseragent_7d4b4643(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_ef6f2ad4 === 'function') {
    await nuxt_plugin_workbox_ef6f2ad4(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_909e88ae === 'function') {
    await nuxt_plugin_metaplugin_909e88ae(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_1d0b0bfa === 'function') {
    await nuxt_plugin_axios_1d0b0bfa(app.context, inject)
  }

  if (typeof nuxt_plugin_router_38a2fef5 === 'function') {
    await nuxt_plugin_router_38a2fef5(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fullscreenMediaplugin4fed59bb_3e0524f6 === 'function') {
    await nuxt_plugin_fullscreenMediaplugin4fed59bb_3e0524f6(app.context, inject)
  }

  if (typeof nuxt_plugin_connectionSpeedpluginServer33f94798_36d6f330 === 'function') {
    await nuxt_plugin_connectionSpeedpluginServer33f94798_36d6f330(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_connectionSpeedplugin028c064a_2d4d0d4c === 'function') {
    await nuxt_plugin_connectionSpeedplugin028c064a_2d4d0d4c(app.context, inject)
  }

  if (typeof nuxt_plugin_oabalyticspluginServer2e79b910_7ef21bb4 === 'function') {
    await nuxt_plugin_oabalyticspluginServer2e79b910_7ef21bb4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_oabalyticsplugin178e40bb_1c7a88ff === 'function') {
    await nuxt_plugin_oabalyticsplugin178e40bb_1c7a88ff(app.context, inject)
  }

  if (typeof nuxt_plugin_contextProvider_28644a8a === 'function') {
    await nuxt_plugin_contextProvider_28644a8a(app.context, inject)
  }

  if (typeof nuxt_plugin_api_5e4622e4 === 'function') {
    await nuxt_plugin_api_5e4622e4(app.context, inject)
  }

  if (typeof nuxt_plugin_breakpoint_4e0af832 === 'function') {
    await nuxt_plugin_breakpoint_4e0af832(app.context, inject)
  }

  if (typeof nuxt_plugin_images_5d5ea524 === 'function') {
    await nuxt_plugin_images_5d5ea524(app.context, inject)
  }

  if (typeof nuxt_plugin_staticInjector_018d5e90 === 'function') {
    await nuxt_plugin_staticInjector_018d5e90(app.context, inject)
  }

  if (typeof nuxt_plugin_businessdomains_42aa9639 === 'function') {
    await nuxt_plugin_businessdomains_42aa9639(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieBanner_7fd495b4 === 'function') {
    await nuxt_plugin_cookieBanner_7fd495b4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtag_0a2cb1e4 === 'function') {
    await nuxt_plugin_gtag_0a2cb1e4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_chatModal_613b9102 === 'function') {
    await nuxt_plugin_chatModal_613b9102(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_6c5806de === 'function') {
    await nuxt_plugin_meta_6c5806de(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
