
import { VSwitch } from "vuetify/lib/components"

export default {
    name: "OSwitch",
    extends: VSwitch,
    methods: {
        genSwitch() {
            // eslint-disable-next-line
            const { title, ...switchAttrs } = this.attrs$
            return this.$createElement(
                "div",
                {
                    staticClass: "v-input--selection-controls__input",
                },
                [
                    this.genInput("checkbox", {
                        ...this.attrs,
                        ...switchAttrs,
                    }),
                    this.genRipple(
                        this.setTextColor(this.validationState, {
                            directives: [
                                {
                                    name: "touch",
                                    value: {
                                        left: this.onSwipeLeft,
                                        right: this.onSwipeRight,
                                    },
                                },
                            ],
                        })
                    ),
                    this.$createElement(
                        "div",
                        {
                            staticClass: "v-input--switch__track",

                            ...this.switchData,
                        },
                        this.getText()
                    ),
                    this.$createElement(
                        "div",
                        {
                            staticClass: "v-input--switch__thumb",
                            ...this.switchData,
                        },
                        [this.genProgress()]
                    ),
                ]
            )
        },
        getText() {
            if (!this.inset) return ""
            return this.lazyValue ? "an" : "aus"
        },
    },
}
