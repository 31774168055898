
import { VExpansionPanel } from "vuetify/lib/components"
import { getSlot } from 'vuetify/lib/util/helpers'
export default {
    name: "ExpansionPanel",
    extends: VExpansionPanel,
    /*        Override render, so wrong aria labels are not added         */
    render(h) {
      return h('div', {
        staticClass: 'v-expansion-panel',
        class: this.classes,
      }, getSlot(this))
    }
}
