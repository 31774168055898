
export default {
    name: "CheckboxField",
    model: {
        event: "input",
        prop: "value",
    },
    props: {
        /** for v-model */
        value: {
            type: Boolean,
            default: false,
        },
        /** Makes checkbox circled */
        rounded: {
            type: Boolean,
            default: true,
        },
        /** Size of a checkbox */
        size: {
            type: Number,
            default: 20,
            validator: (val) => val > 0,
        },
        /** Width of the border */
        borderWidth: {
            type: Number,
            default: 1,
            validator: (val) => val >= 0,
        },
        /** Enables checkmark */
        checkmark: {
            type: Boolean,
            default: false,
        },
        /** */
        checkmarkSize: {
            type: Number,
            default: 18,
            validator: (val) => val > 0,
        },
        strokeWidth: {
            type: Number,
            default: 3,
            validator: (val) => val > 0,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        color: {
            type: Object,
            default: () => ({
                unchecked: "transparent",
                checked: "#000000",
                checkmark: "#ffffff",
            }),
        },
    },
    computed: {
        model: {
            get() {
                return this.value
            },
            set(value) {
                if (this.disabled) return
                this.$emit("input", value)
            },
        },
        inputStyles() {
            return {
                "background-color": this.value
                    ? this.color.checked
                    : this.color.unchecked,
                "padding-left": `${this.size}px`,
                "padding-top": `${this.size}px`,
                "border-width": `${this.borderWidth}px`,
                "border-radius": this.rounded ? "50px" : "0",
                "border-color": this.color.checked,
            }
        },
        checkmarkStyles() {
            return {
                width: `${this.checkmarkSize}px`,
                height: `${this.checkmarkSize}px`,
                "stroke-width": `${this.strokeWidth}px`,
                stroke: this.color.checkmark,
            }
        },
    },
}
