import Vue from "vue"
Vue.mixin({
    beforeCreate: function () {
        if (this.$options.icons) {
            this.icons = this.$options.icons
            delete this.$options.icons
        }
        if (this.$options.routes) {
            this.routes = this.$options.routes
            delete this.$options.routes
        }
        if (this.$options.staticData) {
            Object.keys(this.$options.staticData).forEach(key => {
                this[key] = this.$options.staticData[key]
            })
            delete this.$options.staticData
        }
    },
})
