import { getBusiness } from "@/services/business.service"
import { TRAINEESHIP } from "@/enums/jobtypes"
export default (ctx, inject) => {
  const businessSlug = process.server ? getSlugFromHost(ctx.req?.headers.host) : window?.__NUXT__?.businessSlug
  const businessProvider = new BusinessProvider(businessSlug)

  inject('businessProvider', businessProvider)

  return businessProvider.init(ctx).catch(error => {
    console.error('Error while fetching business', error)
  })

}



class BusinessProvider {
  constructor(businessSlug) {
    this.businessSlug = businessSlug
  }

  async init({ nuxtState, $api, redirect, beforeNuxtRender }) {
    if (!this.businessSlug) return
    if (nuxtState?.headerValue) {
      this.business = nuxtState?.headerValue
      return
    }

    try {
      this.business = await $api.run(getBusiness, this.businessSlug).request
      /** Hide unpublished jobs & traineeships */
      this.business.jobs = this.business.jobs.filter(job => job.is_published && job.type_id !== TRAINEESHIP)
      if (!this.business.has_pro_portal) throw new Error('Business does not have EinBlick')

      beforeNuxtRender(({ nuxtState }) => {
        nuxtState.headerValue = this.business
      })
    } catch (error) {
      console.error(error)
      redirect("https://einblick.pro")
    }
  }
}

function getSlugFromHost(host) {
  if (!host) return
  const hostname = host.replace('www.', '')
  const match = hostname.match(/([a-z-]+)\.(einblick|localhost)/i)
  return match?.[1]
}
