
import { VApp } from "vuetify/lib"
import favicon from "@/mixins/favicon"
import { ThemeProvider } from "@/components/theme"
import { routeToBusinessMain } from "@/helpers/routingEinBlick"
export default {
    name: "ErrorLayout",
    components: { VApp, ThemeProvider },
    mixins: [favicon],
    props: {
        error: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        theme() {
            return this.$businessProvider?.business?.theme?.theme
        },
        route() {
            if (this.$businessProvider?.business) {
                return routeToBusinessMain()
            } else {
                /** Fallback for weird cases */
                return "https://einblick.pro"
            }
        },
    },
}
