
import linkable from "@/mixins/linkable"

export default {
    name: "Card",
    mixins: [linkable],
    props: {
        /** Reduces inner spacing by 50% */
        dense: {
            type: Boolean,
            default: false,
        },
        /** Removes shadow */
        flat: {
            type: Boolean,
            default: false,
        },
        /** Removes border radius */
        tile: {
            type: Boolean,
            default: false,
        },
        /** Adds a background image and image-color based box shadow.
         * Allows multiple background images (gradient, urls) */
        background: {
            type: [String, Array],
            default: undefined,
        },
        /** Sets a different background position for shadow + background */
        backgroundPosition: {
            type: String,
            default: null,
        },
        /** makes only shadow colored by background */
        shadowOnly: {
            type: Boolean,
            default: false,
        },
        /** Adds hover effect */
        hover: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            return {
                'card': true,
                'elevation-3': !this.dense,
                'elevation-1': this.dense,
                "card--dense ": this.dense,
                "card--flat elevation-0": this.flat,
                "card--hover": this.hover,
                "card--tile": this.tile,
                card__container: this.background,
                "card__container--shadow-only":
                    this.background && this.shadowOnly,
            }
        },
        /** Creates background images, including layers. */
        backgroundImage() {
            if (!this.background) return undefined
            let iterable = this.background
            if (typeof iterable === "string") iterable = [iterable]
            iterable = iterable.map((current) => {
                if (current.startsWith("/") || current.startsWith("http")) {
                    return `url(${current})`
                }
                return current
            })
            return {
                "background-image": iterable.join(","),
                "background-position": this.backgroundPosition,
            }
        },
    },
}
