
import { routeToDatenschutz } from "@/helpers/routingEinBlick"
import { mdiChevronRight } from "@mdi/js"
export default {
    name: "CookieBanner",
    routes: {
        routeToDatenschutz,
    },
    icons: {
        mdiChevronRight,
    },
    data: () => ({
        panels: [],
        marketing: false,
        performance: false,
    }),
    created() {
        this.marketing = this.$consent.marketing
        this.performance = this.$consent.performance
    },
    methods: {
        acceptAll() {
            this.$consent.acceptAll()
            this.close()
        },
        acceptCurrent() {
            this.$consent.set({
                marketing: this.marketing,
                performance: this.performance,
            })
            this.close()
        },
        close() {
            this.$destroy()
            this.$el.parentNode.removeChild(this.$el)
        },
    },
}
