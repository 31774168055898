export function routeToBusinessSite(businessSite) {
    return {
        name: "business-index-businessSiteCombinedSlug",
        params: {
            businessSiteCombinedSlug: businessSite?.combined_slug,
        },
    }
}

export function routeToBusinessMain() {
    return {
        name: "business-index",
    }
}

export function routeToJob(job, query) {
    const combinedJobSlug = job.name_slug + "-" + job.slug
    return {
        name: "business-businessSiteCombinedSlug-jobSlug",
        params: {
            businessSiteCombinedSlug: job?.business_site?.combined_slug,
            jobSlug: combinedJobSlug,
        },
        query,
    }
}

export function routeToImpressum() {
    return {
        name: "impressum",
    }
}

export function routeToDatenschutz() {
    return {
        name: "datenschutz",
    }
}
